import { memo, useEffect, useState } from "react"
import { useHttp } from "../hooks/http.hook"
import { useAuth0 } from "@auth0/auth0-react"
import { Col, Row, Typography, Table, Button } from "antd"
import {Link} from "react-router-dom"

const {Title} = Typography

const columns = [
  {
    title: 'Customer Id',
    dataIndex: 'customerId',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: '',
    key: 'action',
    align: 'center',
    render: (_, record) => (
      <Link to={`/${record.customerId}`}><Button>SELECT</Button></Link>
    )
  }
]

const CustomerSelections = () => {
  const { request, loading, error } = useHttp()
  const { getAccessTokenSilently } = useAuth0()
  const [customerList, setCustomerList] = useState([])

  const fetchProducts = async () => {
    try {
      const authToken = await getAccessTokenSilently()

      const res = await request({
        url: "datacore/customers",
        method: "POST",
        body: {
          product: "rankings",
          userIdentityToken: authToken
        }
      })
      if (res?.data?.length) {
        setCustomerList(res.data)
      }
    } catch (e) {
      console.error(e.message, "Error")
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  return <Row>
    <Col span={8}></Col>
    <Col span={8}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Title className={'mt-10 mb-15'} style={{ textAlign: 'center' }} level={3}>Select a customer</Title>
        <Table
          dataSource={customerList}
          columns={columns}
          loading={loading}
          pagination={false}
          rowKey={(record) => record.customerId}
          size={'middle'}
        />
      </div>
    </Col>
    <Col span={8}></Col>
  </Row>
}

export default memo(CustomerSelections)