import { Routes, Route } from "react-router-dom"
import App from "../../App"
import Mock from "../../Pages/Mock"
import CustomerSelections from "../../Pages/CustomerSelections"
import OrganizationSelections from "../../Pages/OrganizationSelections"
import RankingsPage from "../../Pages/Rankings/Rankings"
import CreateRank from "../../Pages/Rankings/CreateRank"
import EditRank from "../../Pages/Rankings/EditRank"
import Ranking from "../../Pages/Rankings/Ranking"
import Seasons from "../../Pages/Rankings/Seasons"
import Teams from "../../Pages/Rankings/Teams"
import Rules from "../../Pages/Rankings/Rules"
import Run from "../../Pages/Rankings/Run"

const Router = () => {
  return (
    <Routes>
      <Route path={'/'} element={<App />}>
        <Route path={":customerId"} element={<Mock/>}>
          <Route path={""} element={<OrganizationSelections/>}/>
          <Route path={":organizationId"} element={<Mock/>}>
            <Route path={"rankings"} element={<RankingsPage/>}/>
            <Route path={"rankings/create"} element={<CreateRank/>}/>
            <Route path={"rankings/edit/:rankingId"} element={<EditRank/>}/>
            <Route path={"rankings/:rankingId"} element={<Ranking/>}>
              <Route path={"seasons"} element={<Seasons/>}/>
              <Route path={"teams"} element={<Teams/>}/>
              <Route path={"rules"} element={<Rules/>}/>
              <Route name={"run"} path={"run"} element={<Run/>}/>
            </Route>
          </Route>
        </Route>
        <Route path={"/customer-selection"} element={<CustomerSelections/>}/>
        <Route path={''} element={<CustomerSelections />} />
      </Route>
    </Routes>
  )
}

export default Router