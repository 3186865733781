import { memo, useEffect, useState } from "react"
import { Button, Radio, Select, Table } from "antd"
import { ArrowDownOutlined, ArrowUpOutlined, DeleteFilled } from "@ant-design/icons"

const OrderRuleList = ["Points", "Wins", "Draws", "Losses", "Played", "Byes"]

const columns = ({ handleRemoveRuleFromList, totalCount, handleChangeRuleOrder }) => [
  {
    title: "Order by",
    dataIndex: "by"
  },
  {
    title: "Descending",
    dataIndex: "descending",
    render: (descending) => descending ? "Yes" : "No"
  },
  {
    title: "Change order",
    width: 1,
    render: (_, record, index) => {
      return (
        <div style={{ display: "flex" }}>
          <Button disabled={index === 0} onClick={() => handleChangeRuleOrder({
            orderBy: record?.by,
            count: index - 1
          })} icon={<ArrowUpOutlined/>}/>
          <Button disabled={index === totalCount - 1} onClick={() => handleChangeRuleOrder({
            orderBy: record?.by,
            count: index + 1
          })} icon={<ArrowDownOutlined/>}/>
        </div>
      )
    }
  },
  {
    render: (record) => <Button onClick={() => handleRemoveRuleFromList(record?.by)} type={"text"}
                                icon={<DeleteFilled/>}/>,
    width: 1
  }
]

const AddOrderRule = ({ ruleId, setNewRuleParam, rankingRules }) => {
  const [sortDesc, setSortDesc] = useState(true)
  const [orderBy, setOrderBy] = useState("Points")
  const [orderRuleList, setOrderRuleList] = useState(rankingRules?.filter(rule => rule?.id === ruleId)?.[0]?.sorts || [])

  const handleRadioChange = ({ target: { value } }) => {
    setSortDesc(value)
  }

  const handleSelectChange = (value) => {
    setOrderBy(value)
  }

  const handleAddOrderRule = () => {
    setOrderRuleList(prev => [...prev, {
      by: orderBy,
      descending: sortDesc
    }])
  }

  const handleRemoveRuleFromList = (orderBy) => {
    setOrderRuleList(prev => prev?.filter(rule => rule.by !== orderBy))
  }

  useEffect(() => {
    setOrderBy(OrderRuleList.filter(ruleName => !orderRuleList.filter(rule => rule.by === ruleName)?.length)?.[0])
    setNewRuleParam({
      id: ruleId,
      sorts: orderRuleList
    })
  }, [orderRuleList])

  const handleChangeRuleOrder = ({ orderBy, count }) => {
    const orderRuleListCopy = [...orderRuleList]
    const active = Object.assign({}, orderRuleList[orderRuleList.findIndex(rule => rule.by === orderBy)])
    const activeIndex = orderRuleList.findIndex(rule => rule.by === orderBy)
    orderRuleListCopy[activeIndex] = Object.assign({}, orderRuleList[count])
    orderRuleListCopy[count] = active
    setOrderRuleList(orderRuleListCopy)
  }

  // useEffect(() => {
  //   const OrderRule = rankingRules?.filter(rule => rule.id === "OrderRule")?.[0] || {
  //     id: ruleId,
  //     sorts: []
  //   }
  //   OrderRule.sorts.push({
  //     by: orderBy,
  //     descending: sortDesc
  //   })
  //   // setNewRuleParam(OrderRule)
  // }, [ruleId, sortDesc, orderBy])

  return <div className={"mt-15"}>
    <div style={{ display: "flex", alignItems: "center" }} className={"mb-10"}>
      <div className={"mr-15"} style={{ width: "50px" }}>Order by:</div>
      <Select placeholder={"Choose rule"} style={{ width: "157px" }} value={orderBy} onChange={handleSelectChange}>
        {OrderRuleList.map(orderBy => <Select.Option disabled={orderRuleList.some(rule => rule?.by === orderBy)}
                                                     key={orderBy}>{orderBy}</Select.Option>)}
      </Select>
    </div>
    <div style={{ display: "flex", alignItems: "center" }} className={"mb-10"}>
      <div className={"mr-15"} style={{ width: "50px" }}>Sort:</div>
      <Radio.Group
        options={[{
          value: false, label: "Ascending"
        }, {
          value: true, label: "Descending"
        }]}
        value={sortDesc}
        onChange={handleRadioChange}
        optionType={"button"}
        buttonStyle={"solid"}
      />
    </div>
    <div style={{ display: "flex", justifyContent: "flex-end" }} className={"mb-10"}>
      <Button onClick={handleAddOrderRule} disabled={!Boolean(orderBy)} type={"primary"}>ADD RULE</Button>
    </div>
    <Table dataSource={orderRuleList}
           size={"small"}
           columns={columns({
             handleRemoveRuleFromList,
             totalCount: orderRuleList?.length || 0,
             handleChangeRuleOrder
           })}
           bordered={true}
           pagination={false}
           rowKey={rule => rule?.by}
    />
  </div>
}

export default memo(AddOrderRule)