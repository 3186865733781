import { Alert, Button, DatePicker, Form, Input, Space } from "antd"
import { memo, useState } from "react"
import moment from "moment/moment"
import { useNavigate, useParams } from "react-router-dom"
import { useHttp } from "../../hooks/http.hook"

const checkChangeValues = (oldValue, newValue) => {
  let changed = false
  if (oldValue.nameLocal !== newValue.nameLocal) {
    changed = true
  }
  if (oldValue.startDate !== newValue.startDate) {
    changed = true
  }
  if (oldValue.endDate !== newValue.endDate) {
    changed = true
  }

  return changed
}

const CreateForm = ({ loading, ranking, nextPage, refetch }) => {
  const { request: createRankRequest, loading: createRankLoading, error: createRankError } = useHttp()
  const { rankingId, customerId, organizationId } = useParams()
  const [mode, setMode] = useState(rankingId ? "edit" : "create")
  const [initialValues, setInitialValues] = useState({
    customerId: parseInt(customerId),
    sport: "hockey",
    nameLocal: ranking?.nameLocal || "",
    nameLatin: ranking?.nameLatin || "",
    startDate: ranking?.startDate ? moment(ranking.startDate).local() : moment(),
    endDate: ranking?.endDate ? moment(ranking?.endDate).local() : null,
    filtersConfiguration: ranking?.filtersConfiguration || "{}",
    rulesConfiguration: ranking?.rulesConfiguration || "{}"
  })
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const sendRequest = async (value) => {
    try {
      let body = {
        ...initialValues,
        ...value,
        nameLatin: value.nameLocal,
        startDate: moment(value.startDate).utc().format("YYYY-MM-DDTHH:mm:ss")
      }
      if (value.endDate) {
        body.endDate = moment(body.endDate).utc().format("YYYY-MM-DDTHH:mm:ss")
      }
      let url = `rankings`
      if (mode === "edit") {
        url += `/${rankingId}`
        body = {
          nameLatin: body.nameLatin,
          startDate: body.startDate
        }
        if (value.endDate !== initialValues.endDate) {
          body.endDate = value.endDate ? moment(body.endDate).utc().format("YYYY-MM-DDTHH:mm:ss") : null
        }
      }
      const res = await createRankRequest({
        url,
        method: mode === "create" ? "POST" : "PUT",
        body
      })
      if (res?.data?.length) {
        return res.data[0]
      }
      return false
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = async (buttonType) => {
    try {
      const value = await form.validateFields()
      // create new
      if (mode === "create") {
        const res = await sendRequest(value)
        if (res) {
          if (buttonType === "continue") {
            navigate(`/${customerId}/${organizationId}/rankings/edit/${res.rankingId}`, {
              state: "afterCreate"
            })
          } else {
            navigate(`/${customerId}/${organizationId}/rankings`)
          }
        }
        return
      }

      //update exists
      const changed = checkChangeValues(initialValues, value)

      if (!changed) {
        if (buttonType === "continue") {
          nextPage()
          return
        } else {
          navigate(`/${customerId}/${organizationId}/rankings`)
          return
        }
      }

      const res = await sendRequest(value)
      if (res) {
        if (buttonType === "continue") {
          await refetch()
          nextPage()
        } else {
          navigate(`/${customerId}/${organizationId}/rankings`)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Form
        onFinish={handleSubmit}
        form={form}
        disabled={loading}
        labelCol={{
          span: 3
        }}
        wrapperCol={{
          span: 5
        }}
        initialValues={initialValues}
        autoComplete={"off"}
      >
        <Form.Item name={"nameLocal"} label={"Local name"} rules={[{
          required: true,
          message: "Please input local name"
        }]}>
          <Input/>
        </Form.Item>
        <Form.Item name={"startDate"} label={"Start date (UTC)"} rules={[{
          required: true,
          message: "Please input start date"
        }]}>
          <DatePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        <Form.Item name={"endDate"} label={"End date (UTC)"}>
          <DatePicker format={"DD/MM/YYYY"}/>
        </Form.Item>
        {createRankError && <Alert message={createRankError} type={"error"}/>}
        <div style={{ display: "flex", justifyContent: "end" }} className={"pt-25"}>
          <Space>
            <Button className={"btn-width"} loading={createRankLoading} type={"primary"}
                    onClick={() => handleSubmit("exit")}>SAVE &
              EXIT</Button>
            <Button className={"btn-width"} loading={createRankLoading} type={"primary"}
                    onClick={() => handleSubmit("continue")}>SAVE &
              CONTINUE</Button>
          </Space>
        </div>
      </Form>
    </>
  )
}

export default memo(CreateForm)