import { memo, useEffect, useState } from "react"
import { useHttp } from "../../hooks/http.hook"
import { Link, useParams } from "react-router-dom"
import { Button, Col, Modal, Row, Table, Typography } from "antd"
import { ExclamationCircleOutlined, DeleteFilled } from "@ant-design/icons"
import moment from "moment"
import RunTable from "./RunTable"

const { confirm } = Modal

const columns = ({ deleteRanking, deleteRankingLoading }) => {
  return [
    // {
    //   title: 'Logo',
    //   key: 'logo',
    //   align: 'center',
    //   width: 60
    // },
    {
      title: "Title",
      dataIndex: "nameLocal"
    },
    {
      title: "Latest run",
      key: "latestRun",
      render: ({ hasRuns, lastSuccessRun }) => hasRuns ? moment.utc(lastSuccessRun).local().format("DD/MM/YYYY") : ""
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        if (status === "ACTIVE") return "Active"
        if (status === "PENDING") return "Inactive"
        return status
      }
    },
    {
      title: "",
      key: "action",
      align: "center",
      render: (_, record) => {
        return record.status === "ACTIVE"
          ? <Link to={`${record.rankingId}`}><Button type={"text"}>MANAGE</Button></Link>
          : <Link to={`edit/${record.rankingId}`}><Button type={"text"}>MANAGE</Button></Link>
      },
      width: 1
    },
    {
      // title: "Latest ranking",
      dataIndex: "hasRuns",
      render: (hasRuns, record) => {
        return <RunTable rankingId={record.rankingId} disabled={!hasRuns} rankingRunId={"latest"}/>
      },
      width: 1
    },
    {
      key: "delete",
      render: (_, record) => <Button onClick={() => {
        confirm({
          icon: <ExclamationCircleOutlined/>,
          content: "Remove ranking?",
          okButtonProps: {
            danger: true,
            loading: deleteRankingLoading
          },
          okText: "Delete",
          onOk (close) {
            deleteRanking({ rankingId: record.rankingId, close })
          }
        })
      }} type={"text"} icon={<DeleteFilled/>}/>,
      width: 1
    }
  ]
}

const Rankings = () => {
  const { request, loading, error } = useHttp()
  const { request: requestDeleteRanking, loading: loadingDeleteRanking, error: errorDeleteRanking } = useHttp()
  let { customerId } = useParams()
  const [rankingsList, setRankingsList] = useState([])

  async function fetchRankings () {
    try {
      const res = await request({
        url: "rankings?sortBy=-added"
      })
      if (res?.data) {
        setRankingsList(res.data)
      }
    } catch (e) {
      console.error(e.message, "Error fetch rankings")
    }
  }

  const deleteRanking = async ({ rankingId, close }) => {
    try {
      const res = await requestDeleteRanking({
        url: `rankings/${rankingId}`,
        method: "DELETE"
      })
      if (res?.data?.length) {
        close()
        fetchRankings()
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchRankings()
  }, [])

  return <Row>
    <Col span={24}>
      <Typography className={"mb-10"}>Latest item</Typography>
      <Table
        size={"middle"}
        loading={loading}
        columns={columns({
          deleteRanking: deleteRanking,
          deleteRankingLoading: loadingDeleteRanking
        })}
        bordered={false}
        dataSource={rankingsList}
        rowKey={item => item.rankingId}
      />
    </Col>
  </Row>
}

export default memo(Rankings)