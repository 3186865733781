import { Skeleton, Space } from "antd"

const RankingPageSkeleton = () => {
  return <>
    <Space>
      <Skeleton.Input active={true}/>
    </Space>
    <br/>
    <br/>
    <Space>
      <Skeleton.Input size={"small"} active={true}/>
      <Skeleton.Input size={"small"} active={true}/>
      <Skeleton.Input size={"small"} active={true}/>
      <Skeleton.Input size={"small"} active={true}/>
    </Space>
    <br/>
    <br/>
    <Space style={{ display: "flex", justifyContent: "space-between" }}>
      <Skeleton.Input size={"small"} active={true}/>
      <Skeleton.Button active={true}/>
    </Space>
    <br/>
    <br/>
    <Skeleton.Input active={true} block={true}/>
    <br/>
    <br/>
    <Skeleton.Input active={true} block={true}/>
    <br/>
    <br/>
    <Skeleton.Input active={true} block={true}/>
    <br/>
    <br/>
    <Skeleton.Input active={true} block={true}/>
  </>
}

export default RankingPageSkeleton