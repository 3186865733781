export const ENVIRONMENT_DEFAULT = "dev"

export const environments = {
  basedomain: {
    local: "localhost",
    dev: "rankings.dev.connect-nonprod.sportradar.dev",
    staging: "rankings.stg.connect-nonprod.sportradar.dev",
    prod: "rankings.connect.sportradar.com"
  },
  api: {
    local: "https://api.rankings.dev.connect-nonprod.sportradar.dev/",
    dev: "https://api.rankings.dev.connect-nonprod.sportradar.dev/",
    staging: "https://api.rankings.stg.connect-nonprod.sportradar.dev/",
    prod: "https://api.rankings.connect.sportradar.com/"
  }
}

export const getEnvironment = (defaultEnv, host) => {
  let domainEnv = defaultEnv // if not specified
  // Detect environment from current host
  for (let key in environments.basedomain) {
    if (environments.basedomain[key]?.includes(host)) {
      domainEnv = key
      break
    }
  }
  return domainEnv
}

export const ENVIRONMENT = getEnvironment(ENVIRONMENT_DEFAULT, window.location.hostname)
