import { memo, useCallback, useEffect, useState } from "react"
import { Alert, Button, Input, Modal, Space, Table, Typography } from "antd"
import { groupBy } from "lodash"
import { DeleteFilled, ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import AddTeamsComponent from "../../Components/Base/AddTeamsComponent"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"
import moment from "moment/moment"

const { confirm } = Modal

const columns = ({ deleteTeam, deleteTeamsLoading }) => {
  return [
    {
      title: "Team",
      dataIndex: "nameLocal"
    },
    {
      title: "Date range",
      dataIndex: "dateRange",
      render: (_, record) => {
        return (
          <span>{moment.utc(record.startDate).local().format("DD-MM-YYYY")} - {record.endDate ? moment.utc(record.endDate).local().format("DD-MM-YYYY") : ""}</span>
        )
      }
    },
    {
      render: (_, record) => <Button onClick={() => {
        confirm({
          icon: <ExclamationCircleOutlined/>,
          content: "Remove team from ranking?",
          okButtonProps: {
            danger: true,
            loading: deleteTeamsLoading
          },
          okText: "Delete",
          onOk (close) {
            deleteTeam({ teamsId: [record.rankingEntityId], close })
          }
        })
      }} type={"text"} icon={<DeleteFilled/>}/>,
      key: "remove",
      width: 1
    }]
}

const Teams = ({ changeDisabledContinueButton = () => {} }) => {
  const { request, loading, error, refetch } = useHttp()
  const { request: deleteTeamsRequest, loading: deleteTeamsLoading, error: deleteTeamsError } = useHttp()
  const { customerId, rankingId } = useParams()
  const [rankTeams, setRankTeams] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [searchValue, setSearchValue] = useState("")

  const fetchRankTeams = useCallback(async () => {
    try {
      const res = await request({
        url: `rankings/${rankingId}/entities`
      })
      if (res?.data) {
        setRankTeams(res?.data)
        if (res.data.length >= 2) {
          changeDisabledContinueButton(false)
        } else {
          changeDisabledContinueButton(true)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [request])

  useEffect(() => {
    fetchRankTeams()

    return () => {
      return changeDisabledContinueButton(false)
    }
  }, [fetchRankTeams])

  const handlerSearchInput = (e) => {
    setSearchValue(e.target.value)
  }

  const deleteTeam = async ({ teamsId, close }) => {
    try {
      const res = await deleteTeamsRequest({
        url: `rankings/${rankingId}/entities/multiple/${teamsId.toString()}`,
        method: "DELETE"
      })
      if (res?.data?.length) {
        if (selectedItems.length) { setSelectedItems([]) }
        close()
        refetch()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onSelectedChange = (selectedRowKeys, itemList) => {
    setSelectedItems(Object.keys(groupBy(itemList, "rankingEntityId")))
  }

  return (
    <>
      <Space style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={"mb-15"}>
        <Typography className={"mb-10"}>Teams list</Typography>
        <Space>
          <Input value={searchValue} onChange={handlerSearchInput} placeholder={"Search"} prefix={<SearchOutlined/>}/>
          <AddTeamsComponent refetch={refetch}/>
        </Space>
      </Space>
      {selectedItems.length ? (
        <Space className={"mb-15"}>
          <div>
            <>
              <Button onClick={() => {
                confirm({
                  icon: <ExclamationCircleOutlined/>,
                  content: `Remove ${selectedItems.length} team(s) from ranking?`,
                  okButtonProps: {
                    danger: true
                  },
                  okText: "Delete",
                  onOk (close) {
                    deleteTeam({ teamsId: selectedItems, close })
                  }
                })
              }} type={"primary"}>Delete</Button> {selectedItems.length} items
            </>
          </div>
        </Space>
      ) : ""}
      {error && <Alert style={{ marginBottom: 5 }} message={error} type={"error"}/>}
      {!loading && rankTeams?.length < 2 &&
        <Alert showIcon style={{ marginBottom: 5 }} message={"Minimum 2 teams"} type={"warning"}/>}
      <Table
        loading={loading}
        rowSelection={{
          onChange: onSelectedChange
        }}
        columns={columns({
          deleteTeam,
          deleteTeamsLoading
        })}
        dataSource={rankTeams.filter(team => {
          if (!searchValue) return true
          return team.nameLocal.toLowerCase().includes(searchValue.toLowerCase())
        })}
        rowKey={record => record.rankingEntityId + "_" + record.startDate}
      />
    </>
  )
}

export default memo(Teams)