import { memo, useEffect, useState } from "react"
import { Alert, Button, Modal, Space, Table, Typography } from "antd"
import {
  DeleteFilled,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons"
import { findIndex, cloneDeep } from "lodash"
import CreateRule from "../../Components/Base/CreateRule"
import { useHttp } from "../../hooks/http.hook"
import { useOutletContext, useParams } from "react-router-dom"

const { confirm, info } = Modal

const columns = ({ changeOrder, totalCount, deleteRule, updateRulesLoading, hasRuns, allRules = [] }) => {
  return [
    {
      title: "Title",
      dataIndex: "id",
      render: (id) => {
        const name = allRules?.filter(rule => rule.id === id)?.[0]?.name
        return name || id
      }
    },
    {
      title: "Details",
      key: "details",
      render: (_, record) => {
        return (
          <Button onClick={() => info({
            title: "Rule details",
            content: <pre style={{ fontSize: "12px" }}>{JSON.stringify(record, null, 2)}</pre>,
            closable: true,
            icon: null
          })}>
            Open details
          </Button>
        )
      },
      width: 1
    },
    {
      title: "Change order",
      key: "changeOrder",
      width: 1,
      render: (_, record, index) => {
        return (
          <div style={{ display: "flex" }}>
            <Button disabled={index === 0 || hasRuns} onClick={() => changeOrder(record?.id, "up")}
                    icon={<ArrowUpOutlined/>}/>
            <Button disabled={index === totalCount - 1 || hasRuns} onClick={() => changeOrder(record?.id, "down")}
                    icon={<ArrowDownOutlined/>}/>
          </div>
        )
      }
    },
    {
      key: "remove",
      render: (_, record) => {
        return <Button onClick={() => confirm({
          icon: <ExclamationCircleOutlined/>,
          content: "Remove rule from ranking?",
          okButtonProps: {
            danger: true,
            loading: updateRulesLoading
          },
          okText: "Delete",
          onOk (close) {
            deleteRule({ ruleId: record.id, close })
          }
        })} type={"text"} disabled={hasRuns} icon={<DeleteFilled/>}/>
      },
      width: 1
    }]
}

const Rules = ({ changeDisabledContinueButton = () => {}, ...props }) => {
  const contextFromOutlet = useOutletContext()
  const ranking = props.ranking ? props.ranking : contextFromOutlet.ranking
  const refetchRank = props.refetchRank ? props.refetchRank : contextFromOutlet.refetch
  const [rankingRules, setRankingRules] = useState(JSON.parse(ranking?.rulesConfiguration)?.ruleSet)
  const [minimumRulesError, setMinimumRulesError] = useState(null)
  const [allRules, setAllRules] = useState([])
  const totalRulesCount = rankingRules?.length
  const { request: requestFetchAllRules, loading: loadingFetchAllRules } = useHttp()
  const {
    request: updateRulesRequest,
    loading: updateRulesLoading,
    error: updateRulesError,
    cleanError: updateRulesCleanError
  } = useHttp()
  const { rankingId } = useParams()

  const fetchAllRules = async () => {
    try {
      const res = await requestFetchAllRules({
        url: `rules`
      })
      if (res?.data?.[0]?.rules?.length) {
        setAllRules(res.data[0].rules)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const updateRules = async (rules) => {
    try {
      const res = await updateRulesRequest({
        url: `rankings/${rankingId}`,
        method: "PUT",
        body: {
          rulesConfiguration: JSON.stringify({
            ruleSet: rules
          })
        }
      })
      if (res?.data?.length) {
        refetchRank()
      }
      return res
    } catch (e) {
      console.error(e)
    }
  }

  const changeOrder = async (itemId, type) => {
    if (updateRulesError) updateRulesCleanError()
    const clone = cloneDeep(rankingRules)
    const index = findIndex(clone, (o) => o?.id === itemId)
    const currentItem = clone[index]
    if (type === "down") {
      clone[index] = clone[index + 1]
      clone[index + 1] = currentItem
    } else {
      clone[index] = clone[index - 1]
      clone[index - 1] = currentItem
    }
    updateRules(clone)
  }

  const deleteRule = async ({ ruleId, close }) => {
    try {
      await updateRules(rankingRules.filter(o => o?.id !== ruleId))
      close()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchAllRules()
  }, [])

  useEffect(() => {
    if (minimumRulesError) {
      changeDisabledContinueButton(true)
    } else {
      changeDisabledContinueButton(false)
    }

    return () => {
      return changeDisabledContinueButton(false)
    }
  }, [minimumRulesError])

  useEffect(() => {
    if (!ranking) {
      return
    }
    if (JSON.parse(ranking?.rulesConfiguration)?.ruleSet?.length > 1) {
      const foundCalc = rankingRules.filter(rule => rule?.id?.includes("Calculation"))?.length
      const foundOrder = rankingRules.filter(rule => rule?.id?.includes("Order"))?.length
      if (!foundCalc || !foundOrder) {
        if (!minimumRulesError) setMinimumRulesError(true)
      }
    } else {
      if (!minimumRulesError) setMinimumRulesError(true)
    }

  }, [ranking])

  return (
    <>
      <Space style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={"mb-15"}>
        <Typography className={"mb-10"}>Select rules</Typography>
        {!ranking?.hasRuns && <CreateRule refetchRank={refetchRank} rankingRules={rankingRules}/>}
      </Space>
      {updateRulesError &&
        <div className={"mb-15"}><Alert message={updateRulesError} type={"error"}/></div>}
      {minimumRulesError &&
        <Alert showIcon style={{ marginBottom: 5 }} message={"Minimum 1 order rule and 1 calculation rule"}
               type={"warning"}/>}
      <Table
        expandable={{
          showExpandColumn: false
        }}
        loading={updateRulesLoading || loadingFetchAllRules}
        columns={columns({
          changeOrder,
          totalCount: totalRulesCount,
          rankingRules,
          deleteRule,
          updateRulesLoading,
          hasRuns: ranking?.hasRuns,
          allRules
        })}
        rowKey={record => record?.id}
        dataSource={rankingRules}
      />
    </>
  )
}

export default memo(Rules)