import { memo, useCallback, useEffect, useState } from "react"
import { Button, Select, Space, Table, Typography, Checkbox, Input, Modal } from "antd"
import { DeleteFilled, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons"
import AddSeasonsComponent from "../../Components/Base/AddSeasonsComponent"
import { useAuth0 } from "@auth0/auth0-react"
import { useHttp } from "../../hooks/http.hook"
import { useParams, useOutletContext } from "react-router-dom"
import moment from "moment"

const { Option } = Select
const { confirm } = Modal

const columns = ({ deleteSeason }) => {
  return [
    {
      title: "Season Title",
      dataIndex: "nameLocal"
    },
    {
      title: "Start",
      dataIndex: "startDate",
      render: (_, record) => {
        return <span>{moment.utc(record.startDate).local().format("DD-MM-YYYY")}</span>
      }
    },
    {
      key: "remove",
      render: (_, record) => <Button
        onClick={() => {
          confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "Remove season from ranking?",
            okButtonProps: {
              danger: true
            },
            okText: "Delete",
            onOk (close) {
              deleteSeason(record.seasonId, close)
            }
          })
        }}
        type={"text"} icon={<DeleteFilled/>}/>,
      width: 1
    }]
}

const FilterPanel = () => {
  return (
    <Space className={"ranking-seasons-filter"}>
      <Select defaultValue={"all"}>
        <Option disabled={true} value={"all"}>All Years</Option>
      </Select>
      <Select defaultValue={"all"}>
        <Option disabled={true} value={"all"}>All Rules</Option>
      </Select>
      <Select defaultValue={"all"}>
        <Option disabled={true} value={"all"}>Date Range</Option>
      </Select>
      <Select defaultValue={"all"}>
        <Option disabled={true} value={"all"}>Users</Option>
      </Select>
      <Select defaultValue={"all"}>
        <Option disabled={true} value={"all"}>Teams</Option>
      </Select>
      <Checkbox>Show advanced info</Checkbox>
    </Space>
  )
}

const Seasons = (props) => {
  const contextFromOutlet = useOutletContext()
  const ranking = props.ranking ? props.ranking : contextFromOutlet.ranking
  const refetchRank = props.refetchRank ? props.refetchRank : contextFromOutlet.refetch
  const [allSeasons, setAllSeasons] = useState([])
  const [existsSeasons, setExistsSeasons] = useState([])
  const { request, loading, error } = useHttp()
  const { request: deleteSeasonRequest, loading: deleteSeasonLoading, error: deleteSeasonError } = useHttp()
  const { rankingId, organizationId } = useParams()
  const [selectedItems, setSelectedItems] = useState([])
  const [searchValue, setSearchValue] = useState("")

  const fetchOrganizationSeasons = async () => {
    try {
      const res = await request({
        url: `datacore/seasons?sport=hockey&organizationId=${organizationId}`
      })
      if (res?.data) setAllSeasons(res.data)
    } catch (e) {
      console.error(e, "error")
    }
  }

  const deleteSeason = async (seasonId, close) => {
    if (!Array.isArray(seasonId)) seasonId = [seasonId]
    try {
      const res = await deleteSeasonRequest({
        url: `rankings/${rankingId}`,
        method: "PUT",
        body: {
          ...ranking,
          filtersConfiguration: JSON.stringify({
            ...JSON.parse(ranking.filtersConfiguration),
            includeSeasonIds: JSON.parse(ranking.filtersConfiguration).includeSeasonIds
              .filter(existsSeasonId => !seasonId.includes(existsSeasonId))
          })
        }
      })
      if (res?.data) {
        refetchRank()
        close()
        setSelectedItems([])
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (allSeasons.length && JSON.parse(ranking.filtersConfiguration)?.includeSeasonIds?.length) {
      setExistsSeasons(allSeasons
        .filter(season => JSON.parse(ranking.filtersConfiguration)?.includeSeasonIds.includes(season.seasonId))
        .filter(season => {
          if (searchValue) {
            return season.nameLocal.toLowerCase().includes(searchValue.toLowerCase())
          }
          return true
        })
      )
    }
  }, [allSeasons, searchValue])

  useEffect(() => {
    fetchOrganizationSeasons()
  }, [])

  const onSelectedChange = (selectedRowKeys) => {
    setSelectedItems(selectedRowKeys)
  }

  const handlerSearchInput = (e) => {
    setSearchValue(e.target.value)
  }

  return (
    <>
      <Space style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={"mb-15"}>
        {/*<FilterPanel />*/}
        <Typography className={"mb-10"}>Seasons</Typography>
        <div>
          {selectedItems.length ? (
            <>
              <Button onClick={() => {
                confirm({
                  icon: <ExclamationCircleOutlined/>,
                  content: `Remove ${selectedItems.length} season(s) from ranking?`,
                  okButtonProps: {
                    danger: true
                  },
                  okText: "Delete",
                  onOk (close) {
                    deleteSeason(selectedItems, close)
                  }
                })
              }} type={"primary"}>Delete</Button> {selectedItems.length} items
            </>
          ) : ""}
        </div>
        <Space>
          <Input value={searchValue} onChange={handlerSearchInput} placeholder={"Search"} prefix={<SearchOutlined/>}/>
          {!ranking?.hasRuns && <AddSeasonsComponent ranking={ranking} refresh={refetchRank}/>}
        </Space>
      </Space>
      <Table
        loading={loading}
        rowSelection={{
          onChange: onSelectedChange
        }}
        columns={columns({
          deleteSeason
        })}
        dataSource={existsSeasons}
        rowKey={"seasonId"}
      />
    </>
  )
}

export default memo(Seasons)