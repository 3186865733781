import { useLocation, useNavigate, useParams } from "react-router-dom"
import { memo, useCallback, useEffect, useState } from "react"
import { useHttp } from "../../hooks/http.hook"
import { Col, Row, Typography, Tabs } from "antd"
import {Outlet} from "react-router-dom"

import "./index.css"
import RankingPageSkeleton from "../../Components/Rankings/RankingPageSkeleton"

const {Title} = Typography
const {TabPane} = Tabs

const Ranking = () => {
  let { customerId, rankingId } = useParams()
  const location = useLocation()
  const { request, loading, error, refetch } = useHttp()
  const navigate = useNavigate()
  const [ranking, setRanking] = useState(null)
  const [defaultActiveKey, setDefaultActiveKey] = useState(null)

  const fetchRanking = useCallback(async () => {
    try {
      const res = await request({
        url: `rankings/${rankingId}`
      })
      if (res?.data?.length) {
        setRanking(res.data[0])
      }
    } catch (e) {
      console.error(e.message, "Error fetch rankings")
    }
  }, [request])

  useEffect(() => {
    fetchRanking()
  }, [fetchRanking])

  useEffect(() => {
    const tabList = ["seasons", "teams", "rules", "run"]
    const pathToArray = location.pathname.split("/")
    let lastItem = pathToArray[pathToArray.length - 1]
    if (!tabList.includes(lastItem)) navigate("seasons")
    setDefaultActiveKey(lastItem)
  }, [location])

  const changeTab = (value) => {
    navigate(value)
  }

  if (loading) return <RankingPageSkeleton/>
  if (!ranking) return "no ranking"

  return (
    <Row id={"ranking"} className={"tabs-wrap"}>
      <Col span={24}>
        <Title level={3}>{ranking.nameLocal}</Title>
        <Tabs activeKey={defaultActiveKey} tabBarGutter={0} defaultActiveKey={defaultActiveKey} onChange={changeTab}>
          <TabPane tab={"Seasons"} key={"seasons"}></TabPane>
          <TabPane tab={"Teams"} key={"teams"}></TabPane>
          <TabPane tab={"Rules"} key={"rules"}></TabPane>
          <TabPane tab={"Run"} key={"run"}></TabPane>
        </Tabs>
        <Outlet context={{ ranking, refetch }}/>
      </Col>
    </Row>
  )
}

export default memo(Ranking)