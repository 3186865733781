import { Alert, Avatar, Button, Divider, Input, List, Select } from "antd"
import { DeleteFilled, PlusOutlined } from "@ant-design/icons"
import { memo, useCallback, useEffect, useState } from "react"
import { useHttp } from "../../../hooks/http.hook"
import { useParams } from "react-router-dom"
import { findIndex } from "lodash"
import AddChildrenTeam from "./AddChildrenTeam"

const { Option } = Select

const AddRuleTeamProportionalGroup = ({ setNewRuleParam, handleChangeDisabledCreateButton }) => {
  const { request: requestAllTeams, loading: loadingAllTeams, error: errorAllTeams } = useHttp()
  const { request: requestRankingTeam, loading: loadingRankingTeam, error: errorRankingTeam } = useHttp()
  const [parentTeam, setParentTeam] = useState("default")
  const [allTeams, setAllTeams] = useState(null)
  const [rankingTeams, setRankingTeams] = useState(null)
  const [childrenTeamsList, setChildrenTeamsList] = useState([])
  const { rankingId, organizationId } = useParams()

  const handleSelectParentTeam = (value) => {
    setParentTeam(value)
  }

  const handleAddChildrenTeam = useCallback(({ teamId, representative }) => {
    const teamObject = {
      ...rankingTeams.filter(o => o.entityId === teamId)?.[0],
      representative
    }
    setChildrenTeamsList(prev => [...prev, teamObject])
  }, [rankingTeams])

  const handleRemoveChildrenTeam = (teamId) => {
    setChildrenTeamsList(prev => prev.filter(o => o.entityId !== teamId))
  }

  const fetchRankTeams = async () => {
    try {
      const res = await requestRankingTeam({
        url: `rankings/${rankingId}/entities`
      })
      if (res?.data) {
        setRankingTeams(res?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchOrganizationTeams = async () => {
    try {
      const res = await requestAllTeams({
        url: `datacore/entities?sport=hockey&organizationId=${organizationId}`
      })
      if (res?.data) {
        setAllTeams(res.data.filter(o => findIndex(rankingTeams, (i => i.entityId === o.entityId)) === -1))
      }
    } catch (e) {
      console.error(e, "error")
    }
  }

  useEffect(() => {
    fetchRankTeams()
  }, [])

  useEffect(() => {
    if (rankingTeams) {
      fetchOrganizationTeams()
    }
  }, [rankingTeams])

  useEffect(() => {
    if (parentTeam === "default" || !childrenTeamsList.length) {
      handleChangeDisabledCreateButton(true)
    } else {
      handleChangeDisabledCreateButton(false)
      setNewRuleParam({
        id: "CalculationRuleEntityProportionalGroup",
        parent: {
          organizationId: organizationId,
          id: parentTeam
        },
        children: childrenTeamsList.map(team => {
          return {
            organizationId: team.organizationId,
            id: team.entityId,
            representative: team.representative
          }
        })
      })
    }
  }, [parentTeam, childrenTeamsList])

  return (
    <div className={"pt-15"}>
      <div style={{ display: "flex", alignItems: "center" }} className={"mb-15"}>
        <span style={{ width: "20%" }} className={"mr-15"}>Parent team:</span>
        <Select loading={loadingAllTeams} onChange={handleSelectParentTeam} value={parentTeam} size={"large"}
                style={{ width: "100%" }}>
          <Option value={"default"} disabled={true}>Select a parent team</Option>
          {allTeams?.map(team => <Option key={team?.entityId} value={team?.entityId}
                                         label={team?.nameFullLocal}>{team?.nameFullLocal}</Option>)}
        </Select>
      </div>
      {/*<Divider/>*/}
      <AddChildrenTeam handleAddChildrenTeam={handleAddChildrenTeam}
                       loadingRankingTeam={loadingRankingTeam}
                       childrenTeamsList={childrenTeamsList}
                       rankingTeams={rankingTeams}
      />
      {/*<Divider/>*/}
      <List
        size={"small"}
        bordered
        dataSource={childrenTeamsList}
        rowKey={"entityId"}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={`${item?.nameLocal} (${item?.representative})`}
            />
            <div>
              <Button type={"text"} icon={<DeleteFilled/>}
                      onClick={() => handleRemoveChildrenTeam(item.entityId)}
              />
            </div>
          </List.Item>
        )}
      />
    </div>)
}

export default memo(AddRuleTeamProportionalGroup)