import { memo, useState } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Modal, Space, Switch, Typography } from "antd"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"

const GenerateRun = ({ refetch }) => {
  const { request, loading, error } = useHttp()
  const { rankingId } = useParams()
  const [visible, setVisible] = useState(false)
  const [configState, setConfigState] = useState({
    isOfficial: true,
    isPublic: true,
    isFromScratch: true
  })

  const showModal = () => setVisible(prev => !prev)

  const onCancelModal = () => {
    showModal()
  }

  const handleCheck = (checked, name) => {
    setConfigState(prev => {
      return {
        ...prev,
        [name]: checked
      }
    })
  }

  const handleSendRequest = async () => {
    try {
      const res = await request({
        url: `rankings/${rankingId}/runs`,
        method: "POST",
        body: configState
      })
      if (res?.data?.length) {
        refetch()
        showModal()
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button type={"primary"} icon={<PlusOutlined/>} onClick={showModal}>GENERATE RUN</Button>
      <Modal
        title={"Generate run"}
        visible={visible}
        okText={"GENERATE"}
        cancelText={"CANCEL"}
        onOk={handleSendRequest}
        okButtonProps={{
          loading: loading
        }}
        cancelButtonProps={{
          disabled: loading
        }}
        onCancel={onCancelModal}
      >
        <Typography.Title level={4}>Configure run</Typography.Title>
        <Space direction={"vertical"}>
          <Typography.Text>
            <Switch checked={configState.isOfficial}
                    onClick={(check) => handleCheck(check, "isOfficial")}
                    style={{ marginRight: "5px" }}/>Is this an official run?</Typography.Text>
          <Typography.Text>
            <Switch checked={configState.isPublic}
                    onClick={(check) => handleCheck(check, "isPublic")}
                    style={{ marginRight: "5px" }}/>Is this a public run?</Typography.Text>
          <Typography.Text>
            <Switch checked={configState.isFromScratch}
                    onClick={(check) => handleCheck(check, "isFromScratch")}
                    style={{ marginRight: "5px" }}/>Do you want to build this run from scratch?</Typography.Text>
        </Space>
      </Modal>
    </>
  )
}

export default memo(GenerateRun)