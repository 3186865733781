import { memo, useEffect, useState } from "react"
import { DeleteFilled, PlusOutlined } from "@ant-design/icons"
import { Alert, Avatar, Button, List, Modal, Select, Typography } from "antd"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"
import { groupBy } from "lodash"

const { Text } = Typography
const { Option } = Select

const AddTeamsComponent = ({ ranking, refresh }) => {
  const [visible, setVisible] = useState(false)
  const { rankingId, organizationId } = useParams()
  const { request, loading } = useHttp()
  const {
    request: addSeasonsRequest,
    loading: addSeasonsLoading,
    error: addSeasonsError,
    cleanError: addSeasonsCleanError
  } = useHttp()
  const [seasons, setSeasons] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const [selectValue, setSelectValue] = useState("default")

  const addSeasonsSendRequest = async () => {
    try {
      const res = await addSeasonsRequest({
        url: `rankings/${rankingId}`,
        method: "PUT",
        body: {
          ...ranking,
          filtersConfiguration: JSON.stringify({
            ...JSON.parse(ranking.filtersConfiguration),
            includeSeasonIds: JSON.parse(ranking.filtersConfiguration)?.includeSeasonIds?.length
              ? [...JSON.parse(ranking.filtersConfiguration).includeSeasonIds, ...Object.keys(groupBy(selectedItems, "seasonId"))]
              : Object.keys(groupBy(selectedItems, "seasonId"))
          })
        }
      })
      return !!res?.data
    } catch (e) {
      console.error(e)
    }
  }

  const showModal = () => setVisible(prev => !prev)

  const onOkModal = async () => {
    const res = await addSeasonsSendRequest()
    if (res) {
      showModal()
      refresh()
    }
  }

  const onCancelModal = () => {
    showModal()
    setSelectedItems([])
  }

  const handleSelectChange = (value) => {
    if (addSeasonsError) {
      addSeasonsCleanError()
    }
    setSelectValue(value)
    setActiveItem(value)
  }

  const addTeamToSelectedItems = () => {
    setSelectedItems([
      ...selectedItems,
      seasons.filter(team => team.seasonId === activeItem)[0]
    ])
    setSelectValue("default")
  }

  const removeTeamFromSelectedItems = (seasonId) => {
    setSelectedItems(prev => prev.filter(team => team.seasonId !== seasonId))
  }

  const fetchOrganizationSeasons = async () => {
    try {
      const res = await request({
        url: `datacore/seasons?sport=hockey&organizationId=${organizationId}`
      })
      if (res?.data) setSeasons(res.data)
    } catch (e) {
      console.error(e, "error")
    }
  }

  useEffect(() => {
    fetchOrganizationSeasons()
  }, [])

  return (
    <>
      <Button type={"primary"} icon={<PlusOutlined/>} onClick={showModal}>SEASONS</Button>
      <Modal
        title={"Add seasons"}
        visible={visible}
        onOk={onOkModal}
        okButtonProps={{
          loading: addSeasonsLoading
        }}
        okText={"DONE"}
        onCancel={onCancelModal}
        cancelText={"CANCEL"}
      >
        <div>
          <Text>Use the search function below to search and add teams, simply search and click the add button to select
            all your teams, then press done when complete.</Text>
        </div>
        <div style={{ width: "100%", display: "flex" }} className={"pt-15 pb-15"}>
          <Select
            loading={loading}
            value={selectValue}
            style={{ width: "100%" }}
            size={"large"}
            onChange={handleSelectChange}
            showSearch
            optionFilterProp={"label"}
            filterOption={true}
          >
            <Option value={"default"} disabled={true}>
              <img className={"mr-5"} width={25} height={25}
                   src="/assets/img/shield-halved-solid.svg"
                   alt="logo"/>
              Season
            </Option>
            {seasons?.length && seasons
              .filter(season => !Object.keys(groupBy(selectedItems, "seasonId")).includes(season.seasonId))
              .filter(season => !JSON.parse(ranking.filtersConfiguration)?.includeSeasonIds?.includes(season.seasonId))
              .map(season => {
                return <Option key={season?.seasonId} value={season?.seasonId} label={season?.nameLocal}>
                  <img className={"mr-5"} width={25} height={25}
                       src={season?.images?.[0]?.url || "/assets/img/shield-halved-solid.svg"}
                       alt="logo"/>
                  {season?.nameLocal}
                </Option>
              })}
          </Select>
          <Button icon={<PlusOutlined/>} disabled={selectValue === "default"} onClick={addTeamToSelectedItems}
                  size={"large"}/>
        </div>
        {addSeasonsError && <Alert message={addSeasonsError} type={"error"}/>}
        {selectedItems.length ?
          <List
            size={"small"}
            bordered
            dataSource={selectedItems}
            rowKey={"seasonId"}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar size={"small"}
                                  src={item?.images?.[0]?.url || "/assets/img/shield-halved-solid.svg"}/>}
                  title={item?.nameLocal}
                />
                <div>
                  <Button type={"text"} icon={<DeleteFilled/>}
                          onClick={() => removeTeamFromSelectedItems(item.seasonId)}/>
                </div>
              </List.Item>
            )}
          /> : ""}
      </Modal>
    </>
  )
}

export default memo(AddTeamsComponent)