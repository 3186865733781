import { memo, useCallback, useEffect, useState } from "react"
import { List, Typography, DatePicker, Space, Button, Alert, Modal } from "antd"
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons"
import moment from "moment"
import { useHttp } from "../../hooks/http.hook"
import { Link, useParams } from "react-router-dom"

const { Title, Text, Paragraph } = Typography

const convertRuleType = (type) => {
  switch (type) {
    case "orderRuleSet":
      return "Order rule(s)"
    case "calculationRuleSet":
      return "Calculation rule"
    default:
      return "Rule"
  }
}

const DisplayDateRange = ({ ranking }) => {
  return <Space>
    From
    <DatePicker defaultValue={moment(ranking.startDate, "YYYY-MM-DD")} format={"DD/MM/YYYY"} disabled/>
    {ranking.endDate && <>
      To
      <DatePicker defaultValue={moment(ranking.endDate, "YYYY-MM-DD")} format={"DD/MM/YYYY"} disabled/>
    </>}
  </Space>
}

const DisplayRules = ({ rules }) => {
  return <>
    <Paragraph><Text strong>Rules applied</Text></Paragraph>
    {Object.keys(rules).map(type => {
      return (<div key={type}>
        <p className={"mb-0"}>{convertRuleType(type)}</p>
        <List
          size={"small"}
          dataSource={rules[type]}
          // header={type}
          bordered
          style={{ marginBottom: 5 }}
          renderItem={(rule) => <List.Item>
            {rule.id}
          </List.Item>}
        >
        </List>
      </div>)
    })}
  </>
}

const BottomButtonsLine = ({ customerId, organizationId, loadingUpdateRanking, setRankingToActive }) => {
  return <div style={{ display: "flex", justifyContent: "end" }} className={"pt-25"}>
    <Space>
      <Link to={`/${customerId}/${organizationId}/rankings`}>
        <Button loading={loadingUpdateRanking} type={"primary"}
                className={"btn-width"}
        >SAVE & EXIT</Button>
      </Link>
      <Button
        className={"btn-width"}
        loading={loadingUpdateRanking}
        type={"primary"}
        style={{ background: "#50a000", borderColor: "#50a000" }}
        onClick={setRankingToActive}
      >FINISH</Button>
    </Space>
  </div>
}

const SuccessModalAfterUpdate = ({ customerId, organizationId, rankingId }) => {
  return <Modal
    title={<Title style={{ marginBottom: 0 }} level={4}>Ranking Completed</Title>}
    // title={'Ranking Completed'}
    visible={true}
    footer={null}
    closable={false}
  >
    <Paragraph>You have successfully created a new rank!</Paragraph>
    <Paragraph>You can now view and run the ranking created, or simply return to rhe ranking screen and continue
      creating new rank types</Paragraph>
    <div style={{ textAlign: "center" }}>
      <Link to={`/${customerId}/${organizationId}/rankings`}>
        <Button className={"mr-10"} type={"primary"}>RETURN TO RANKINGS</Button>
      </Link>
      <Link to={`/${customerId}/${organizationId}/rankings/${rankingId}`}>
        <Button type={"primary"}>VIEW CREATED RANKING</Button>
      </Link>
    </div>
  </Modal>
}

const Confirmation = ({ ranking }) => {
  const {
    request: requestFetchRankingTeams,
    loading: loadingFetchRankingTeams,
    error: errorFetchRankingTeams
  } = useHttp()
  const {
    request: requestUpdateRanking,
    loading: loadingUpdateRanking,
    error: errorUpdateRanking
  } = useHttp()
  const [rankTeamsCount, setRankTeamsCount] = useState(0)
  const { rankingId, customerId, organizationId } = useParams()
  const [successUpdate, setSuccessUpdate] = useState(null)

  const seasons = JSON.parse(ranking.filtersConfiguration)?.includeSeasonIds
  const rules = JSON.parse(ranking.rulesConfiguration)

  const fetchRankTeams = useCallback(async () => {
    try {
      const res = await requestFetchRankingTeams({
        url: `rankings/${rankingId}/entities`
      })
      if (res?.data) setRankTeamsCount(res.data.length)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    fetchRankTeams()
  }, [])

  const setRankingToActive = async () => {
    try {
      const res = await requestUpdateRanking({
        url: `rankings/${rankingId}`,
        method: "PUT",
        body: {
          status: "active"
        }
      })
      if (res?.data?.length) {
        if (res.data[0].status === "ACTIVE") {
          setSuccessUpdate(1)
          return
        }
      }
      setSuccessUpdate(0)
    } catch (e) {
      console.error(e)
    }
  }

  return <>
    <Title level={4}>Ranking runs</Title>
    {errorUpdateRanking && <Alert type={"error"} message={errorUpdateRanking}/>}
    <p className={"mb-0"}>The following settings will be applied to the rank run:</p>
    <Paragraph style={{ marginBottom: 0 }}><Text strong>{seasons?.length} Seasons selected</Text></Paragraph>
    {!loadingFetchRankingTeams && <Paragraph><Text strong>{rankTeamsCount} Teams selected</Text></Paragraph>}
    <DisplayRules rules={rules}/>
    <Paragraph><Text strong>Date range</Text></Paragraph>
    <DisplayDateRange ranking={ranking}/>
    <BottomButtonsLine
      customerId={customerId}
      organizationId={organizationId}
      loadingUpdateRanking={loadingUpdateRanking}
      setRankingToActive={setRankingToActive}
    />
    {successUpdate === 1 &&
      <SuccessModalAfterUpdate organizationId={organizationId} customerId={customerId} rankingId={rankingId}/>}
  </>
}

export default memo(Confirmation)