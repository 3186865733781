import { memo } from "react"
import { Card, Col, Row, Space, Typography } from "antd"
import { Link } from "react-router-dom"
import CacheRefresh from "../Base/CacheRefresh"
import ClearCache from "../Base/ClearCache"

const { Text, Title } = Typography

const CreateRank = () => {
  return <Link to={"create"} style={{ width: "250px", display: "flex" }} className={"quick-link-card"}><Card
    size={"small"}
    className={"rank-quick-link-button shadow-1"}
  >
    <Title level={5} style={{ marginBottom: 0 }}>CREATE RANK</Title>
    <Text>Add a new Ranking to manage within your Organisation</Text>
  </Card></Link>
}

const QuickLinks = () => {
  return (
    <Row className={"mb-20"}>
      <Col span={24}>
        <Typography className={"mb-10"}>Quick links</Typography>
        <Space>
          <CreateRank/>
          <CacheRefresh/>
          <ClearCache/>
        </Space>
      </Col>
    </Row>
  )
}

export default memo(QuickLinks)