import { memo, useEffect, useState } from "react"
import { Button, Modal, Table, Typography } from "antd"
import { useHttp } from "../../hooks/http.hook"
import { CaretUpOutlined, CaretDownOutlined, MinusOutlined, EyeFilled } from "@ant-design/icons"
import moment from "moment"

const { Text } = Typography

const PositionIcon = ({ current, prev = current }) => {
  if (current === prev) return <MinusOutlined className={"mr-5"}/>
  if (current < prev) return <CaretUpOutlined style={{ color: "#67b521" }} className={"mr-5"}/>
  return <CaretDownOutlined style={{ color: "#9d2121" }} className={"mr-5"}/>
}

const columns = [
  {
    title: <Text strong={true}>Current rank</Text>,
    dataIndex: "position",
    render: (position, record) => <Text strong={true}>
      <PositionIcon current={position} prev={record?.prevRankingRow?.position}/>{position}
    </Text>
  },
  {
    title: <Text strong={true}>Team</Text>,
    dataIndex: "rankingEntity",
    render: (rankingEntity) => <Text strong={true}>{rankingEntity?.nameLocal}</Text>
  },
  {
    title: <Text strong={true}>Points</Text>,
    dataIndex: "points",
    render: (points) => <Text strong={true}>{parseFloat(points).toFixed(2)}</Text>
  },
  {
    title: <Text strong={true}>Previous points</Text>,
    dataIndex: "prevRankingRow",
    render: (prevRankingRow) => <Text
      strong={true}>{prevRankingRow?.points ? parseFloat(prevRankingRow?.points).toFixed(2) : 0}</Text>
  },
  {
    title: <Text strong={true}>Previous rank</Text>,
    dataIndex: "prevRankingRow",
    render: (prevRankingRow) => prevRankingRow?.position ? <Text strong={true}>{prevRankingRow?.position}</Text> :
      <MinusOutlined/>
  }
]

const RunTable = ({ rankingRunId, disabled = false, rankingId }) => {
  const [visible, setVisible] = useState(false)
  const [prevRunId, setPrevRunId] = useState(null)
  const [rankingRows, setRankingRows] = useState([])
  const [prevRankingRows, setPrevRankingRows] = useState([])
  const [currentRankingRunDate, setCurrentRankingRunDate] = useState(null)
  const showModal = () => setVisible(prev => !prev)
  const { request, loading, error } = useHttp()
  const { request: requestPrevRanking, loading: loadingPrevRanking, error: errorPrevRanking } = useHttp()
  const { request: requestRunList, loading: loadingRunList, error: errorRunList } = useHttp()

  const getPrevRunId = async () => {
    try {
      const res = await requestRunList({
        url: `rankings/${rankingId}/runs?sortBy=-added`
      })
      if (res?.data?.length) {
        let currentIndex = res.data.findIndex(run => run?.rankingRunId === rankingRunId)
        if (rankingRunId === "latest") {
          currentIndex = res.data.findIndex(run => run?.isCompleted && !run?.isFailed)
        }

        setCurrentRankingRunDate(res.data[currentIndex].added)

        const prevRun = res.data.slice(currentIndex + 1).filter(run => run.isCompleted && !run.isFailed)?.[0]
        setPrevRunId(prevRun?.rankingRunId || "last")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchPrevRankingRunRows = async () => {
    try {
      const res = await requestPrevRanking({
        url: `rankings/${rankingId}/runs/${prevRunId}/rows?include=rankingEntities`
      })
      if (res?.data?.length) {
        const { rankingEntities } = res?.includes?.resources

        setPrevRankingRows(res.data.sort((a, b) => a.position - b.position).map(item => {
          return {
            ...item,
            rankingEntity: rankingEntities?.[item?.rankingEntityId]
          }
        }))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchRankingRunRows = async () => {
    try {
      const res = await request({
        url: `rankings/${rankingId}/runs/${rankingRunId}/rows?include=rankingEntities`
      })
      if (res?.data?.length) {
        const { rankingEntities } = res?.includes?.resources

        setRankingRows(res.data.sort((a, b) => a.position - b.position).map(item => {
          return {
            ...item,
            rankingEntity: rankingEntities?.[item?.rankingEntityId],
            prevRankingRow: prevRankingRows?.filter(run => run?.rankingEntityId === item?.rankingEntityId)?.[0]
          }
        }))
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (visible && !rankingRows.length) {
      getPrevRunId()
    }
  }, [visible])

  useEffect(() => {
    if (prevRunId) {
      if (prevRunId !== "last") {
        fetchPrevRankingRunRows()
      } else {
        fetchRankingRunRows()
      }
    }
  }, [prevRunId])

  useEffect(() => {
    if (prevRankingRows.length) {
      fetchRankingRunRows()
    }
  }, [prevRankingRows])

  return <>
    <Button disabled={disabled} onClick={showModal} icon={<EyeFilled/>} type={"text"}>VIEW</Button>
    <Modal
      visible={visible}
      title={"Rank table"}
      onCancel={showModal}
      onOk={showModal}
      width={"60%"}
      footer={null}
    >
      <Table
        dataSource={rankingRows}
        columns={columns}
        loading={loading || loadingRunList || loadingPrevRanking}
        pagination={false}
        size={"middle"}
        rowKey={({ rankingRunRowId }) => rankingRunRowId}
        footer={() => <div style={{ textAlign: "right" }}>
          <Text>{moment.utc(currentRankingRunDate).local().format("DD/MM/YYYY HH:mm:ss")}</Text>
        </div>}
      />
    </Modal>
  </>
}

export default memo(RunTable)