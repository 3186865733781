import { useCallback, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { ENVIRONMENT, environments } from "../config/Environment"

export const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { getIdTokenClaims } = useAuth0()
  const [shouldRefetch, refetch] = useState({})

  const request = useCallback(async ({
    url,
    method = 'GET',
    body = null,
    headers = {}
  }) => {
    setLoading(true)
    try {
      const token = await getIdTokenClaims();

      headers['Authorization'] = `Bearer ${token.__raw}`
      if (body) {
        body = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
      }

      const response = await fetch(environments.api[ENVIRONMENT] + url, { method, body, headers })
      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.massage || data.errors?.message || 'Unexpected error')
      }

      setLoading(false)

      return data
    } catch (e) {
      setLoading(false)
      setError(e.message)
      throw e
    }
  }, [shouldRefetch])

  const cleanError = useCallback(() => setError(null), [])

  return { loading, error, request, cleanError, refetch: () => refetch(new Date()) }
}