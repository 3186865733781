import { memo } from "react"
import { Button } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useNavigate, useLocation, useParams, Link } from "react-router-dom"

const GoBack = () => {
  const navigate = useNavigate()
  const location = useLocation()
  let { customerId, organizationId } = useParams()

  const editMode = location.pathname.split("/").includes("edit") || location.pathname.split("/").includes("create")

  if (editMode) {
    return <Link to={`/${customerId}/${organizationId}/rankings`}>
      <Button type={"text"} icon={<ArrowLeftOutlined/>}>Back to dashboard</Button>
    </Link>
  }

  return (
    <Button type={"text"} onClick={() => navigate(-1)} icon={<ArrowLeftOutlined/>}>Back</Button>
  )
}

export default memo(GoBack)