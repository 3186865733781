import { memo, useCallback, useEffect, useState } from "react"
import { Button, Col, Modal, notification, Row, Select } from "antd"
import { findIndex, cloneDeep, startsWith } from "lodash"
import { PlusOutlined } from "@ant-design/icons"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"
import AddOrderRule from "./Rules/AddOrderRule"
import AddCalcRule from "./Rules/AddCalcRule"

const { Option } = Select

const CreateRule = ({ rankingRules, refetchRank }) => {
  const [rankingRulesCopy, setRankingRulesCopy] = useState(cloneDeep(rankingRules))
  const { request, loading } = useHttp()
  const {
    request: createRuleRequest,
    loading: createRuleLoading,
    error: createRuleError,
    cleanError: createRuleCleanError
  } = useHttp()
  const [allRules, setAllRules] = useState(null)
  const [visible, setVisible] = useState(false)
  const [selectValue, setSelectValue] = useState("default")
  const { rankingId } = useParams()
  const [newRule, setNewRule] = useState(null)
  const [disabledCreateButton, setDisabledCreateButton] = useState(false)

  const showModal = () => {
    setVisible(prev => !prev)
    setSelectValue("default")
  }

  const handleChangeDisabledCreateButton = useCallback((value) => {
    setDisabledCreateButton(value)
  }, [])

  const setNewRuleParam = useCallback((rule) => {
    setNewRule(rule)
  }, [])

  const fetchRules = async () => {
    try {
      const res = await request({
        url: `rules`
      })
      if (res?.data?.[0]?.rules?.length) {
        setAllRules(res.data[0].rules)
      }
    } catch (e) {
      console.error(e, "error")
    }
  }

  useEffect(() => {
    fetchRules()
  }, [])

  useEffect(() => {
    if (createRuleError) {
      notification.error({
        message: createRuleError,
        placement: "bottomRight"
      })
      createRuleCleanError()
    }
  }, [createRuleError])

  const handleSelectChange = (value) => {
    setSelectValue(value)
  }

  const createRuleSendRequest = async () => {
    let newRuleList = []
    if (!rankingRulesCopy?.length) {
      newRuleList.push(newRule)
    } else {
      // replace previous "OrderRule" if it exists
      if (newRule?.id === "OrderRule") {
        newRuleList = [...rankingRulesCopy.filter(rule => rule?.id !== "OrderRule"), newRule]
      } else {
        newRuleList = [...rankingRulesCopy, newRule]
      }
    }
    try {
      const res = await createRuleRequest({
        url: `rankings/${rankingId}`,
        method: "PUT",
        body: {
          rulesConfiguration: JSON.stringify({
            ruleSet: newRuleList
          })
        }
      })
      if (res?.data?.length) {
        refetchRank()
        showModal()
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (<>
    <Button type={"primary"} icon={<PlusOutlined/>} onClick={showModal}>ADD RULE</Button>
    <Modal
      title={"Create rule"}
      visible={visible}
      onCancel={showModal}
      okText={"CREATE"}
      onOk={createRuleSendRequest}
      okButtonProps={{
        loading: createRuleLoading,
        disabled: disabledCreateButton
      }}
    >
      <Row>
        <Col span={24}>
          <Select
            className={"mb-15"}
            value={selectValue}
            onChange={handleSelectChange}
            placeholder={"Choose rule"} style={{ width: "100%" }} size={"large"} loading={loading}>
            <Option value={"default"} disabled={true}>
              Select a rule
            </Option>
            {allRules && allRules.map(rule => {
              const found = findIndex(rankingRulesCopy, (o) => o?.id === rule?.id && o?.id !== "OrderRule") !== -1
              return <Option disabled={found} key={rule?.id} value={rule?.id}>{rule?.name}</Option>
            })}
          </Select>
        </Col>
        <Col span={24}>
          {startsWith(selectValue, "Order") &&
            <AddOrderRule handleChangeDisabledCreateButton={handleChangeDisabledCreateButton} ruleId={selectValue}
                          setNewRuleParam={setNewRuleParam} rankingRules={rankingRules}/>}
          {startsWith(selectValue, "Calculation") &&
            <AddCalcRule handleChangeDisabledCreateButton={handleChangeDisabledCreateButton} ruleId={selectValue}
                         setNewRuleParam={setNewRuleParam}/>}
        </Col>
      </Row>
    </Modal>
  </>)
}

export default memo(CreateRule)