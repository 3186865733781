import { memo, useState } from "react"
import { Alert, Button, Input, Select } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { findIndex } from "lodash"

const { Option } = Select

const AddChildrenTeam = ({ loadingRankingTeam, rankingTeams, childrenTeamsList, handleAddChildrenTeam }) => {
  const [childrenTeam, setChildrenTeam] = useState("default")
  const [representativeValue, setRepresentativeValue] = useState("")
  const [validError, setValidError] = useState(null)

  const handleRepresentativeInput = (e) => {
    setRepresentativeValue(e.target.value)
    if (validError) setValidError(null)
  }

  const handleSelectChildrenTeam = (value) => {
    setChildrenTeam(value)
    if (validError) setValidError(null)
  }

  const addChildrenTeam = () => {
    if (!representativeValue) {
      setValidError("Representative input is required")
      return
    }
    handleAddChildrenTeam({ teamId: childrenTeam, representative: representativeValue })
    setChildrenTeam("default")
    setRepresentativeValue("")
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }} className={"mb-15"}>
        <span style={{ width: "20%" }} className={"mr-15"}>Children team:</span>
        <Select loading={loadingRankingTeam} onChange={handleSelectChildrenTeam} value={childrenTeam} size={"large"}
                style={{ width: "100%" }}>
          <Option value={"default"} disabled={true}>Select a children team</Option>
          {rankingTeams
            ?.filter(team => {
              if (!childrenTeamsList?.length) {
                return true
              }

              return findIndex(childrenTeamsList, (o) => team.entityId === o.entityId) === -1
            })
            ?.map(team => <Option key={team?.entityId} value={team?.entityId}
                                  label={team?.nameLocal}>{team?.nameLocal}</Option>)}
        </Select>
        {/*<Button icon={<PlusOutlined/>} disabled={childrenTeam === "default"}*/}
        {/*        onClick={() => handleAddChildrenTeam(childrenTeam)}*/}
        {/*        size={"large"}/>*/}
      </div>
      {childrenTeam !== "default" &&
        <>
          <div style={{ display: "flex", alignItems: "center" }} className={"mb-15"}>
            <span style={{ width: "20%" }} className={"mr-15"}>Representative:</span>
            <Input onChange={handleRepresentativeInput} value={representativeValue} status={validError ? "error" : ""}
                   size={"large"}
                   placeholder={"Input representative"}/>
            <Button
              icon={<PlusOutlined/>}
              disabled={childrenTeam === "default"}
              onClick={addChildrenTeam}
              size={"large"}/>
          </div>
          {validError && <Alert style={{ marginBottom: 15 }} type={"error"} message={validError}/>}
        </>
      }
    </>
  )
}

export default memo(AddChildrenTeam)