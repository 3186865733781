import { memo, useEffect, useState } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Modal, Select, Typography, DatePicker, Input, Row, Col, Alert } from "antd"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"
import moment from "moment"

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const AddTeamsComponent = ({ refetch }) => {
  const [visible, setVisible] = useState(false)
  const { rankingId, organizationId } = useParams()
  const { request, loading } = useHttp()
  const {
    request: addTeamsRequest,
    loading: addTeamsLoading,
    error: addTeamsError,
    cleanError: addTeamsCleanError
  } = useHttp()
  const [teams, setTeams] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [selectValue, setSelectValue] = useState("default")
  const [dateRangeValue, setDateRangeValue] = useState([moment().add(-10, "Y"), moment().add(10, "Y")])
  const [initPointsValue, setInitPointsValue] = useState(1000)

  const addTeamsSendRequest = async () => {
    const body = {
      organizationId: activeItem.organizationId,
      entityId: activeItem.entityId,
      initialPoints: initPointsValue,
      nameLocal: activeItem.nameFullLocal,
      nameLatin: activeItem.nameFullLatin || activeItem.nameFullLocal,
      startDate: dateRangeValue[0].utc().format("YYYY-MM-DD"),
      endDate: dateRangeValue[1].utc().format("YYYY-MM-DD")
    }
    try {
      const res = await addTeamsRequest({
        url: `rankings/${rankingId}/entities`,
        method: "POST",
        body
      })
      if (res?.data?.length) {
        showModal()
        refetch()
        setActiveItem(null)
        setSelectValue("default")
        if (addTeamsError) {
          addTeamsCleanError()
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const showModal = () => setVisible(prev => !prev)

  const onCancelModal = () => {
    showModal()
    setActiveItem(null)
    setSelectValue("default")
    if (addTeamsError) {
      addTeamsCleanError()
    }
  }

  const handleSelectChange = (value) => {
    setSelectValue(value)
    setActiveItem(teams.filter(item => item.entityId === value)?.[0])
  }

  const fetchOrganizationTeams = async () => {
    try {
      const res = await request({
        url: `datacore/entities?sport=hockey&organizationId=${organizationId}`
      })
      if (res?.data) setTeams(res.data)
    } catch (e) {
      console.error(e, "error")
    }
  }

  useEffect(() => {
    fetchOrganizationTeams()
  }, [])

  const handleRangePicker = (value) => {
    setDateRangeValue(value)
  }

  return (
    <>
      <Button type={"primary"} icon={<PlusOutlined/>} onClick={showModal}>TEAMS</Button>
      <Modal
        title={"Add teams"}
        visible={visible}
        onOk={addTeamsSendRequest}
        okButtonProps={{
          loading: addTeamsLoading
        }}
        okText={"ADD"}
        onCancel={onCancelModal}
        cancelText={"CANCEL"}
      >
        <div>
          <Text>Use the search function below to search and add teams, simply search and click the add button to select
            all your teams, then press add when complete.</Text>
        </div>
        <div style={{ width: "100%", display: "flex" }} className={"pt-15 pb-15"}>
          <Select
            loading={loading}
            value={selectValue}
            style={{ width: "100%" }}
            size={"large"}
            onChange={handleSelectChange}
            showSearch
            optionFilterProp={"label"}
            filterOption={true}
          >
            <Option value={"default"} disabled={true}>
              <img className={"mr-5"} width={25} height={25}
                   src="/assets/img/shield-halved-solid.svg"
                   alt="logo"/>
              Team
            </Option>
            {teams?.length && teams
              .map(team => {
                return <Option key={team?.entityId} value={team?.entityId} label={team?.nameFullLocal}>
                  <img className={"mr-5"} width={25} height={25}
                       src={team?.images?.[0]?.url || "/assets/img/shield-halved-solid.svg"}
                       alt="logo"/>
                  {team?.nameFullLocal}
                </Option>
              })}
          </Select>
        </div>
        {addTeamsError && <Alert message={addTeamsError} type={"error"}/>}
        {activeItem && (
          <div className={"mt-15"}>
            <Row className={"mb-10"}>
              <Col span={5} style={{ display: "flex", alignItems: "center" }}>
                <Text>Date range</Text>
              </Col>
              <Col span={10}>
                <RangePicker onChange={handleRangePicker} format={"DD/MM/YYYY"} value={dateRangeValue}/>
              </Col>
            </Row>
            <Row className={"mb-10"}>
              <Col span={5} style={{ display: "flex", alignItems: "center" }}>
                <Text>Initial points</Text>
              </Col>
              <Col span={10}>
                <Input value={initPointsValue} disabled={true}/>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </>
  )
}

export default memo(AddTeamsComponent)