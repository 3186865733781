import { useAuth0 } from "@auth0/auth0-react"
import LeftSide from "./Components/Base/LeftSide"
import Header from "./Components/Base/Header"
import Footer from "./Components/Base/Footer"
import { Layout, Space, Typography } from "antd"
import { Outlet, useLocation } from "react-router-dom"
import { memo, useEffect, useState } from "react"
import BreadcrumbComponent from "./Components/Base/Breadcrumb"
import GoBack from "./Components/Base/GoBack"
import ShowCustomerName from "./Components/Base/ShowCustomerName"

const { Content, Sider } = Layout

function App () {
  const {
    loginWithRedirect,
    isAuthenticated,
    logout,
    user,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims
  } = useAuth0()
  const { pathname } = useLocation()
  const splitPath = pathname.split("/")

  if (isLoading) return "Loading..."
  if (!isAuthenticated) loginWithRedirect({ appState: { returnTo: window.location.pathname } })

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <LeftSide/>
      <Layout>
        <Header/>
      <Layout>
        <Space direction={"vertical"} className={"p-25 pb-0 grey-gb"}>
          <BreadcrumbComponent/>
          {(splitPath.includes("rankings") && splitPath[splitPath.length - 1] !== "rankings") && <GoBack/>}
          {splitPath[splitPath.length - 1] === "rankings" && <ShowCustomerName/>}
        </Space>
        <Content className={"p-25"}>
          <Outlet/>
        </Content>
        <Footer />
        {/*<Sider>Right Sider</Sider>*/}
      </Layout>
    </Layout>
    </Layout>
  )
}

export default memo(App);
