import { memo, useCallback, useEffect } from "react"
import { Card, notification, Typography } from "antd"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"
import { LoadingOutlined } from "@ant-design/icons"

const { Text, Title } = Typography

const CacheRefresh = () => {
  const { request, loading, error, cleanError } = useHttp()
  const { organizationId } = useParams()

  const sendRequest = useCallback(async () => {
    try {
      const res = await request({
        url: `rankings/cache-data/refresh?sport=hockey&organizationId=${organizationId}`,
        method: "POST"
      })
      if (res?.data) {
        notification.success({
          message: "Success",
          placement: "bottomRight"
        })
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        placement: "bottomRight"
      })
      cleanError()
    }
  }, [error])

  return <div>
    {/*<Button type={"primary"} loading={loading} onClick={sendRequest}>REFRESH CACHE DATA</Button>*/}
    <Card
      onClick={sendRequest}
      size={"small"}
      className={"rank-quick-link-button shadow-1 quick-link-card"}
    >
      <div style={{ display: "flex" }}>
        {loading &&
          <LoadingOutlined
            style={{
              fontSize: 24
            }}
            className={"mr-5"}
            spin
          />}
        <Title level={5} style={{ marginBottom: 0 }}>REFRESH COMPETITION DATA</Title>
      </div>
      <Text>Fetch updated competition data for your Rankings</Text>
    </Card>
  </div>
}

export default memo(CacheRefresh)