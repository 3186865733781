import { Skeleton, Spin, Typography } from "antd"
import { memo, useEffect, useState } from "react"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

const { Title } = Typography

const ShowCustomerName = () => {
  let { customerId } = useParams()
  const { request, loading, error } = useHttp()
  const [customer, setCustomer] = useState(null)
  const { getAccessTokenSilently } = useAuth0()

  const fetchCustomers = async () => {
    if (!customerId) {
      setCustomer(null)
      return
    }
    try {
      const authToken = await getAccessTokenSilently()
      const res = await request({
        url: "datacore/customers",
        method: "POST",
        body: {
          product: "rankings",
          userIdentityToken: authToken
        }
      })
      if (res?.data) {
        const found = res.data.filter(item => item.customerId === parseInt(customerId))?.[0]
        if (found) {
          setCustomer(found)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchCustomers()
  }, [customerId])

  if (loading) return <Skeleton.Input active={true}/>
  if (!customer) return "User not found"

  return <Title level={3} style={{ marginBottom: 0 }}>{customer.name}</Title>
}

export default memo(ShowCustomerName)