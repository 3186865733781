import { memo, useEffect, useState } from "react"
import { useHttp } from "../hooks/http.hook"
import { useAuth0 } from "@auth0/auth0-react"
import { Col, Row, Typography, Table, Button, Alert } from "antd"
import { Link, useParams } from "react-router-dom"

const { Title } = Typography

const columns = [
  {
    title: "Organization Id",
    dataIndex: "organizationId"
  },
  {
    title: "Name",
    dataIndex: "nameLocal"
  },
  {
    title: "Sport",
    dataIndex: "sport"
  },
  {
    title: "",
    key: "action",
    align: "center",
    render: (_, record) => (
      <Link to={`${record.organizationId}/rankings`}><Button>SELECT</Button></Link>
    )
  }
]

const OrganizationSelections = () => {
  const { request, loading, error, cleanError } = useHttp()
  const { getAccessTokenSilently } = useAuth0()
  const [organizationsList, setOrganizationsList] = useState([])
  const { customerId } = useParams()

  useEffect(() => {
    if (organizationsList.length && error) cleanError()
  }, [organizationsList])

  const fetchProducts = async () => {
    try {
      const authToken = await getAccessTokenSilently()

      const res = await request({
        url: "datacore/organizations",
        method: "POST",
        body: {
          customerId,
          userIdentityToken: authToken,
          product: "rankings"
        }
      })
      if (res?.data?.length) {
        setOrganizationsList(res.data.filter(org => org.sport === "hockey"))
      }
    } catch (e) {
      console.error(e.message, "Error")
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  return <Row>
    <Col span={5}/>
    <Col span={14}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title className={"mt-10 mb-15"} style={{ textAlign: "center" }} level={3}>Select organization</Title>
        {error && <Alert message={error} type={"error"}/>}
        <Table
          dataSource={organizationsList}
          columns={columns}
          loading={loading}
          pagination={false}
          rowKey
          size={"middle"}
        />
      </div>
    </Col>
    <Col span={5}/>
  </Row>
}

export default memo(OrganizationSelections)