import { memo, useCallback, useEffect, useState } from "react"
import { Col, Divider, Form, Input, Row, Typography, DatePicker, Select, Button, Space, Alert, Steps } from "antd"
import { useParams } from "react-router-dom"
import moment from "moment"
import { useHttp } from "../../hooks/http.hook"
import CreateForm from "../../Components/Rankings/CreateForm"

const { Title, Text } = Typography
const { Option } = Select
const { Step } = Steps

const CreateRank = () => {
  const { customerId, rankingId, organizationId } = useParams()
  const [currentStep, setCurrentStep] = useState(0)

  const changeStep = (value) => setCurrentStep(value)
  const nextPage = () => setCurrentStep(prev => prev + 1)

  return (
    <Row>
      <Col span={19} className={"pr-25"}>
        <CreateForm customerId={customerId}/>
      </Col>
      <Col span={5}>
        <Steps current={currentStep} direction={"vertical"} size={"small"}>
          <Step title={"Enter Ranking Details"}/>
          <Step title={"Add seasons"}/>
          <Step title={"Add teams"}/>
          <Step title={"Add rules"}/>
          <Step title={"Ranking complete"}/>
        </Steps>
      </Col>
    </Row>
  )
}

export default memo(CreateRank)