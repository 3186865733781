import { memo } from "react"
import { Breadcrumb, Col, Row } from "antd"
import { Link, useLocation, useParams } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"

const BreadcrumbComponent = () => {
  let { customerId, organizationId } = useParams()
  const { pathname } = useLocation()
  const splitPath = pathname.split("/")

  return (
    <Row>
      <Col span={24}>
        <Breadcrumb separator={<RightOutlined/>}>
          <Breadcrumb.Item><Link to={"/"}>Sportradar</Link></Breadcrumb.Item>
          {(splitPath.includes("rankings") && splitPath[splitPath.length - 1] !== "rankings") &&
            <Breadcrumb.Item><Link to={`/${customerId}/${organizationId}/rankings`}>Rankings</Link></Breadcrumb.Item>}
        </Breadcrumb>
      </Col>
    </Row>
  )
}

export default memo(BreadcrumbComponent)