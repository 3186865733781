import { memo } from "react"
import { Tabs } from "antd"

const { TabPane } = Tabs

const TabPanel = () => {
  return (
    <div className={"tabs-wrap"}>
      <Tabs>
        <TabPane tab={"Dashboard"} key={"dashboard"}></TabPane>
      </Tabs>
    </div>
  )
}

export default memo(TabPanel)