import { memo } from "react"
import Rankings from "../../Components/Rankings/Rankings"
import QuickLinks from "../../Components/Rankings/QuickLinks"
import TabPanel from "../../Components/Rankings/TabPanel"

const RankingsPage = () => {
  return (
    <>
      <TabPanel/>
      <QuickLinks/>
      <Rankings />
    </>
  )
}

export default memo(RankingsPage)