import { memo, useCallback, useEffect, useRef, useState } from "react"
import { Alert, Button, Modal, Space, Table, Typography } from "antd"
import { DeleteFilled, CheckCircleFilled, CloseCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"
import { useHttp } from "../../hooks/http.hook"
import GenerateRun from "../../Components/Rankings/GenerateRun"
import RunTable from "../../Components/Rankings/RunTable"
import moment from "moment"

const { confirm } = Modal

const columns = ({ deleteRun, loadingRemoveRankingRun, rankingId }) => {
  return [
    {
      title: "Created",
      dataIndex: "added",
      render: (added) => moment.utc(added).local().format("DD/MM/YYYY HH:mm")
    },
    {
      title: "Generated?",
      key: "isCompleted",
      render: ({ isCompleted }) => isCompleted ? <CheckCircleFilled style={{ color: "#12892b" }}/> : <CloseCircleFilled
        style={{ color: "#ce4535" }}/>,
      width: 100
    },
    {
      title: "Successful?",
      key: "isFailed",
      render: ({ isFailed }) => isFailed ? <CloseCircleFilled style={{ color: "#ce4535" }}/> : <CheckCircleFilled
        style={{ color: "#12892b" }}/>,
      width: 100
    },
    {
      title: "Public?",
      key: "isPublic",
      render: ({ isPublic }) => isPublic ? <CheckCircleFilled
        style={{ color: "#12892b" }}/> : <CloseCircleFilled style={{ color: "#ce4535" }}/>,
      width: 100
    },
    {
      title: "Official?",
      key: "isOfficial",
      render: ({ isOfficial }) => isOfficial ? <CheckCircleFilled style={{ color: "#12892b" }}/> : <CloseCircleFilled
        style={{ color: "#ce4535" }}/>,
      width: 100
    },
    // {
    //   title: "From scratch?",
    //   key: "isFromScratch",
    //   render: ({ isFromScratch }) => isFromScratch ? <CheckCircleFilled style={{ color: "#12892b" }}/> :
    //     <CloseCircleFilled
    //       style={{ color: "#ce4535" }}/>
    // },
    {
      title: "View Table",
      dataIndex: "rankingRunId",
      render: (rankingRunId, record) => {
        return <RunTable rankingId={rankingId} disabled={!record.isCompleted || record.isFailed}
                         rankingRunId={rankingRunId}/>
      },
      width: 100
    },
    {
      dataIndex: "rankingRunId",
      render: (rankingRunId) => <Button onClick={() => {
        confirm({
          icon: <ExclamationCircleOutlined/>,
          content: "Remove ranking run?",
          okButtonProps: {
            danger: true,
            loading: loadingRemoveRankingRun
          },
          okText: "Delete",
          onOk (close) {
            deleteRun({ runId: rankingRunId, close })
          }
        })
      }} type={"text"} icon={<DeleteFilled/>}/>,
      width: 1
    }
  ]
}

const Run = () => {
  const { request, loading, error, refetch } = useHttp()
  const { request: requestRemoveRankingRun, loading: loadingRemoveRankingRun, error: errorRemoveRankingRun } = useHttp()
  const { rankingId } = useParams()
  const [rankingRuns, setRankingRuns] = useState([])
  const [runInProgress, setRunInProgress] = useState(false)
  const timeoutRef = useRef()

  const fetchRankRuns = useCallback(async () => {
    try {
      const res = await request({
        url: `rankings/${rankingId}/runs?sortBy=-added`
      })
      if (res?.data) {
        const foundRunInProgress = res.data.some(item => !item.isCompleted)
        setRunInProgress(foundRunInProgress)
        setRankingRuns(res.data)
      }
    } catch (e) {
      console.error(e)
    }
  }, [request])

  const deleteRun = async ({ runId, close }) => {
    try {
      const res = await requestRemoveRankingRun({
        url: `rankings/${rankingId}/runs/${runId}`,
        method: "DELETE"
      })
      if (res?.data?.length) {
        close()
        refetch()
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchRankRuns()
  }, [fetchRankRuns])

  useEffect(() => {
    if (runInProgress) {
      timeoutRef.current = setInterval(() => {
        fetchRankRuns()
      }, 2000)
    } else {
      clearInterval(timeoutRef.current)
    }
  }, [runInProgress])

  return (
    <>
      <Space style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className={"mb-15"}>
        <Typography className={"mb-10"}>Runk runs</Typography>
        <GenerateRun refetch={refetch}/>
      </Space>
      {error && <Alert type={"error"} message={error}/>}
      <Table
        loading={loading}
        columns={columns({
          deleteRun,
          loadingRemoveRankingRun,
          rankingId
        })}
        dataSource={rankingRuns}
        rowKey={item => item?.rankingRunId}
      />
    </>
  )
}

export default memo(Run)