import { Button, Layout, Skeleton, Space, Typography } from "antd"
import { MenuOutlined, CloseOutlined, LogoutOutlined } from "@ant-design/icons"
import { memo, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useHttp } from "../../hooks/http.hook"
import { useParams } from "react-router-dom"

const { Sider } = Layout

const ProductsListInfo = {
  "Competition Management": {
    link: "https://app.competitionmanagement.sportradar.com/",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-competition"}/>
  },
  "Competition Admin": {
    link: "https://app.competitionmanagement.sportradar.com/",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-competition"}/>
  },
  "Live Production Monitor": {
    link: "https://pm.%BASEDOMAIN%",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-production"}/>
  },
  "User Manager": {
    link: "https://um.%BASEDOMAIN%",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-users"}/>
  },
  "Video Stream Manager": {
    link: "https://vm.%BASEDOMAIN%",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-video"}/>
  },
  "EVO Admin": {
    link: "https://evo.%BASEDOMAIN%",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-evo"}/>
  },
  "Datacore Admin": {
    link: "https://admin.dc.%BASEDOMAIN%",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-datacore"}/>
  },
  "Datacore": {
    link: "https://admin.dc.%BASEDOMAIN%",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-datacore"}/>
  },
  "Schedule Manager": {
    link: "https://d45erdalr3dh.cloudfront.net/schedule-manager/",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-schedule"}/>
  },
  "s3 Image Manager": {
    link: "https://d45erdalr3dh.cloudfront.net/image-manager/",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-images"}/>
  },
  "EVO Operations": {
    link: "https://d45erdalr3dh.cloudfront.net/dashboard/",
    icon: <i style={{ fontSize: "1.1rem" }} className={"multi-color-icon-evo"}/>
  },
  "Rankings": {
    link: "/",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21.25" viewBox="0 0 26 21.25">
      <g id="Group_18659" data-name="Group 18659" transform="translate(-2 -1315.094)">
        <g id="Group_18659-2" data-name="Group 18659" transform="translate(11.114 1316)">
          <text id="star" transform="translate(3.886 6.094)" fill="red" fontSize="7"
                fontFamily="FontAwesome6Pro-Solid, 'Font Awesome \36  Pro'">
            <tspan x="-3.938" y="0">star</tspan>
          </text>
        </g>
        <path id="Path_10384" data-name="Path 10384"
              d="M6.839-12.625H1.654a1.114,1.114,0,0,0-1.1,1.1V-4.04a1.114,1.114,0,0,0,1.1,1.1H6.839a1.114,1.114,0,0,0,1.1-1.1v-7.487A1.114,1.114,0,0,0,6.839-12.625ZM15.9-7.417H11.368a1.114,1.114,0,0,0-1.1,1.1V-4.04a1.114,1.114,0,0,0,1.1,1.1H15.9A1.114,1.114,0,0,0,17-4.04V-6.318A1.114,1.114,0,0,0,15.9-7.417Zm-18.618-2.6H-7.9A1.114,1.114,0,0,0-9-8.922V-4.04a1.114,1.114,0,0,0,1.1,1.1h5.185a1.114,1.114,0,0,0,1.1-1.1V-8.922A1.114,1.114,0,0,0-2.717-10.021Z"
              transform="translate(11 1339.285)" fill="red"/>
      </g>
    </svg>
  }
}

const UserMenu = ({ user, logout, collapsed }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img className={"mb-10"} style={{ maxWidth: "40px", borderRadius: "50px" }} src={user?.picture} alt="img"/>
        {!collapsed && user.name}
      </div>
      <Button size={"large"}
              type={"text"}
              onClick={() => logout({ returnTo: window.location.origin })}
              className={"mb-10"}
        // icon={<i className={'multi-color-icon-logout'} />}
      >
        <i className={`multi-color-icon-logout ${!collapsed ? "pr-5" : ""}`}/>
        {!collapsed && "LOGOUT"}
      </Button>
    </div>
  )
}

const ProductIconList = ({ name }) => {
  return <a className={"mb-5 mt-5"}
            style={{ width: "30px" }}
            target={"_blank"}
            href={ProductsListInfo[name]?.link}>
    {ProductsListInfo[name]?.icon}
  </a>
}

const LeftSide = () => {
  const { user, logout, getAccessTokenSilently } = useAuth0()
  const [collapsed, setCollapsed] = useState(true)
  const { request, loading, error } = useHttp()
  const [productList, setProductList] = useState([])
  const { customerId } = useParams()

  const fetchProducts = async () => {
    try {
      const authToken = await getAccessTokenSilently()

      const res = await request({
        url: "datacore/products",
        method: "POST",
        body: {
          customerId,
          userIdentityToken: authToken
        }
      })

      if (res?.data?.length) {
        setProductList(res.data)
      }
    } catch (e) {
      console.error(e.message, "Error")
    }
  }

  useEffect(() => {
    if (customerId) fetchProducts()
  }, [customerId])

  return <Sider style={{ borderRight: "2px solid #e9edee" }} trigger={null} collapsible collapsed={collapsed}>
    <div style={{
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: collapsed ? "80px" : "200px",
      position: "fixed"
    }}>
      <div
        style={{ display: "flex", alignItems: "center", height: "64px", justifyContent: "center", maxWidth: "77px" }}>
        <Button size={"large"} type={"text"} onClick={() => setCollapsed(prev => !prev)}
                icon={collapsed ? <MenuOutlined/> : <CloseOutlined/>}/>
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        marginTop: "25px"
      }}>
        {loading && (
          <Space direction={"vertical"} align={"center"}>
            <Skeleton.Button active={true}/>
            <Skeleton.Button active={true}/>
            <Skeleton.Button active={true}/>
          </Space>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {productList.map(item => <div key={item?.code}
                                        style={{ display: "flex", alignItems: "center", marginLeft: "28px" }}>
            {!loading && <ProductIconList name={item?.name}/>}
            {!collapsed && <Typography.Text strong style={{ fontSize: "18px", whiteSpace: "nowrap" }}
                                            className={"ml-5"}>{item?.name}</Typography.Text>}
          </div>)}
        </div>
        <UserMenu collapsed={collapsed} user={user} logout={logout}/>
      </div>
    </div>
  </Sider>
}

export default memo(LeftSide)