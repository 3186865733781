import { memo, useEffect } from "react"
import AddRuleTeamProportionalGroup from "./AddRuleTeamProportionalGroup"

const AddCalcRule = ({ ruleId, setNewRuleParam, handleChangeDisabledCreateButton }) => {

  useEffect(() => {
    if (ruleId !== "CalculationRuleEntityProportionalGroup") {
      setNewRuleParam({
        id: ruleId
      })
    }
  }, [ruleId])

  if (ruleId !== "CalculationRuleEntityProportionalGroup") return ""

  return <AddRuleTeamProportionalGroup handleChangeDisabledCreateButton={handleChangeDisabledCreateButton}
                                       setNewRuleParam={setNewRuleParam}/>
}

export default memo(AddCalcRule)