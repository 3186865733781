import { memo, useCallback, useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useHttp } from "../../hooks/http.hook"
import { Alert, Button, Col, Divider, Popover, Row, Space, Spin, Steps, Typography } from "antd"
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons"
import CreateForm from "../../Components/Rankings/CreateForm"
import Seasons from "./Seasons"
import Teams from "./Teams"
import Rules from "./Rules"
import Confirmation from "./Confirmation"

const { Step } = Steps
const { Title } = Typography

const customDot = (dot, { status, index }) => (
  <>
    {status === "error" && <WarningOutlined/>}
    {status === "finish" && <CheckCircleOutlined/>}
    {status === "process" && dot}
    {status === "wait" && dot}
  </>
)

const EditRank = () => {
  let { state } = useLocation()
  const { customerId, rankingId, organizationId } = useParams()
  const [ranking, setRanking] = useState(null)
  const { request, loading, error, refetch } = useHttp()
  const [currentStep, setCurrentStep] = useState(state === "afterCreate" ? 1 : 0)
  const [disabledContinueButton, setDisabledContinueButton] = useState(false)
  const navigate = useNavigate()

  const changeStep = (value) => setCurrentStep(value)
  const nextPage = () => setCurrentStep(prev => prev + 1)
  const prevPage = () => setCurrentStep(prev => prev - 1)

  const fetchRankin = useCallback(async () => {
    try {
      const res = await request({
        url: `rankings/${rankingId}`
      })
      if (res?.data?.length) {
        if (res.data[0].status === "ACTIVE") {
          navigate(`/${customerId}/${organizationId}/rankings/${rankingId}`)
        }
        setRanking(res.data[0])
      }
    } catch (e) {
      console.error(e.message, "Error fetch rankings")
    }
  }, [request])

  useEffect(() => {
    fetchRankin()
  }, [fetchRankin])

  const ShowSpaceTitle = () => {
    switch (currentStep) {
      case 0:
        return <Title level={3}>Enter Ranking Details</Title>
      case 1:
        return <Title level={3}>Add Seasons</Title>
      case 2:
        return <Title level={3}>Add Teams</Title>
      case 3:
        return <Title level={3}>Add Rules</Title>
      case 4:
        return <Title level={3}>Confirmation / Review</Title>
      default:
        return <Title level={3}>Enter Ranking Details</Title>
    }
  }

  const changeDisabledContinueButton = useCallback((value) => {
    setDisabledContinueButton(value)
  }, [])

  if (loading) return <Spin/>
  if (error) return <Alert message={error} type={"error"}/>

  if (ranking) {
    return (
      <Row>
        <Col span={19} className={"pr-25"}>
          <ShowSpaceTitle/>
          <Divider/>
          {currentStep === 0 && <CreateForm nextPage={nextPage} refetch={refetch} loading={loading} ranking={ranking}/>}
          {currentStep === 1 && <Seasons refetchRank={refetch} ranking={ranking}/>}
          {currentStep === 2 && <Teams ranking={ranking} changeDisabledContinueButton={changeDisabledContinueButton}/>}
          {currentStep === 3 && <Rules refetchRank={refetch} ranking={ranking}
                                       changeDisabledContinueButton={changeDisabledContinueButton}/>}
          {currentStep === 4 && <Confirmation ranking={ranking}/>}
          {(currentStep !== 4 && currentStep !== 0) && (
            <div style={{ display: "flex", justifyContent: "end" }} className={"pt-25"}>
              <Space>
                {currentStep > 0 && <Button onClick={prevPage}>BACK</Button>}
                {/*{currentStep === 0 && <Button disabled={loading}>CLEAR</Button>}*/}
                <Link to={`/${customerId}/${organizationId}/rankings`}>
                  <Button loading={loading}
                          className={"btn-width"}
                          type={"primary"}
                          disabled={disabledContinueButton}
                          htmlType={"submit"}>SAVE & EXIT</Button>
                </Link>
                {/*<Button loading={loading} type={'primary'} onClick={saveAndContinue}>SAVE & CONTINUE</Button>*/}
                <Button disabled={disabledContinueButton}
                        className={"btn-width"}
                        loading={loading}
                        type={"primary"}
                        onClick={nextPage}>{currentStep === 0 ? "SAVE & " : ""}CONTINUE</Button>
              </Space>
            </div>
          )}
        </Col>
        <Col span={5}>
          {/*<Steps current={currentStep} progressDot={customDot} direction={"vertical"} onChange={changeStep}>*/}
          <Steps current={currentStep} direction={"vertical"} size={"small"}>
            <Step title={"Enter Ranking Details"}/>
            <Step title={"Add seasons"}/>
            <Step title={"Add teams"}/>
            <Step title={"Add rules"}/>
            <Step title={"Confirmation / Review"}/>
          </Steps>
        </Col>
      </Row>
    )
  }
}

export default memo(EditRank)