import { memo } from "react"
import { Layout, Space, Typography } from "antd"

const {Text} = Typography

const { Footer} = Layout

const FooterComponent = () => {
  return <Footer>
    <Space>
      <span>@ 2022 SportRadar</span>
      <a href={'/'}><Text strong={true}>Support</Text></a>
      <a href={'/'}><Text strong={true}>Terms & Conditions</Text></a>
    </Space>
  </Footer>
}

export default memo(FooterComponent)